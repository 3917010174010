import { useMemo } from 'react';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import {
    useGetClientSMSTemplateQuery,
    useCreateClientSMSTemplateMutation,
    useDeleteClientSMSTemplateMutation,
    useUpdateClientSMSTemplateMutation,
    useGetAllClientSMSTemplatesQuery,
} from '../redux/api/client-sms-template';

export type TUseClientSMSTemplateQueries = 'single' | 'all';

interface IUseClientSMSTemplateConfig {
    queries?: TUseClientSMSTemplateQueries[];
    templateId?: number | string | null;
}

export const useClientSMSTemplate = ({
    queries = [],
    templateId,
}: IUseClientSMSTemplateConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');

    const skipTemplateQuery = !queries.includes('single') || !templateId;
    const skipAllTemplatesQuery = !queries.includes('all');

    const getTemplateQuery = useGetClientSMSTemplateQuery(templateId, { skip: skipTemplateQuery });
    const getAllTemplatesQuery = useGetAllClientSMSTemplatesQuery(undefined, {
        skip: skipAllTemplatesQuery,
    });
    const [create, createMutation] = useCreateClientSMSTemplateMutation();
    const [update, updateMutation] = useUpdateClientSMSTemplateMutation();
    const [deleteTemplate, deleteMutation] = useDeleteClientSMSTemplateMutation();

    const allErrors = useMemo(() => {
        return getRtkQueryErrors(getAllTemplatesQuery.error, t.errors);
    }, [getAllTemplatesQuery.error, t.errors]);
    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getTemplateQuery.error, t.errors);
    }, [getTemplateQuery.error, t.errors]);
    const createErrors = useMemo(() => {
        return getRtkQueryErrors(createMutation.error, t.errors);
    }, [createMutation.error, t.errors]);
    const updateErrors = useMemo(() => {
        return getRtkQueryErrors(updateMutation.error, t.errors);
    }, [updateMutation.error, t.errors]);
    const deleteErrors = useMemo(() => {
        return getRtkQueryErrors(deleteMutation.error, t.errors);
    }, [deleteMutation.error, t.errors]);

    return {
        all: {
            data: getAllTemplatesQuery.data,
            isFetching: getAllTemplatesQuery.isFetching,
            isLoading: getAllTemplatesQuery.isLoading,
            isSuccess: getAllTemplatesQuery.isSuccess,
            isError: getAllTemplatesQuery.isError,
            errors: allErrors,
        },
        single: {
            data: getTemplateQuery.data,
            isFetching: getTemplateQuery.isFetching,
            isLoading: getTemplateQuery.isLoading,
            isSuccess: getTemplateQuery.isSuccess,
            isError: getTemplateQuery.isError,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            isUnauthorized: getTemplateQuery.error?.status === 403,
            errors: singleErrors,
        },
        create: {
            fetch: create,
            data: createMutation.data,
            isLoading: createMutation.isLoading,
            isSuccess: createMutation.isSuccess,
            isError: createMutation.isError,
            errors: createErrors,
        },
        update: {
            fetch: update,
            data: updateMutation.data,
            isLoading: updateMutation.isLoading,
            isSuccess: updateMutation.isSuccess,
            isError: updateMutation.isError,
            errors: updateErrors,
        },
        delete: {
            fetch: deleteTemplate,
            data: deleteMutation.data,
            isLoading: deleteMutation.isLoading,
            isSuccess: deleteMutation.isSuccess,
            isError: deleteMutation.isError,
            errors: deleteErrors,
        },
    };
};
