import React from 'react';

import { container } from './alert-info.module.scss';

import IconFactory from '../hoc/icon-factory';

interface IAlertInfoProps {
    className?: string;
    children?: React.ReactNode;
}

const AlertInfo: React.FC<IAlertInfoProps> = ({ className = '', children }) => {
    return (
        <div className={`${container} ${className}`}>
            <IconFactory icon="alert" />
            {children}
        </div>
    );
};

export default AlertInfo;
