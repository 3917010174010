import SectionHeaderClient from '../components/sections/header-client';
import SectionClientSmsTemplatePreview from '../components/sections/client-sms-template-preview';
import SectionFooter from '../components/sections/footer';

import React from 'react';
import { graphql, navigate } from 'gatsby';

import { layout, loader } from '../templates/client.module.scss';
import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { ISetting } from '../models/setting.model';
import { EClientStatus, IClient } from '../models/client.model';
import { getNodes } from '../utils/get-nodes';
import getPageConfigFromSections from '../utils/get-page-config-from-sections';
import getSectionsWithRepeatIndex from '../utils/get-sections-with-repeat-index';
import getCustomStyleFromSetting from '../utils/get-custom-style-from-settings';
import { useClient } from '../hooks/use-client';
import { isBrowser } from '../utils/is-browser';
import { getUrlSearch } from '../utils/get-url-search';

import MainLayout from '../layouts/main-layout';
import SectionFactory from '../components/hoc/section-factory';
import ThemeStyle from '../components/atoms/theme-style';
import Loader from '../components/atoms/loader';

type TRedirectPaths = Record<
    'login-client' | 'verification-client' | 'client-order-create',
    string
>;

interface ICandidateProps {
    readonly data: {
        page: IPage;
        allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'type'>>;
        allThemeSetting: IQueryAllResult<ISetting>;
    };
}

const Client: React.FC<ICandidateProps> = ({ data }) => {
    const { page, allPage, allThemeSetting } = data;
    const themeSettings = getNodes(allThemeSetting);
    const customStyle = getCustomStyleFromSetting(themeSettings);
    const client = useClient();
    const search = getUrlSearch();

    const { mainSectionIndex } = getPageConfigFromSections(page.sections);
    const sections = getSectionsWithRepeatIndex(page.sections);

    const redirectPaths = Object.fromEntries(
        getNodes(allPage).map(({ type, pathname }) => [type, pathname])
    ) as TRedirectPaths;

    if (!client.isLoggedIn && isBrowser()) {
        navigate(`${redirectPaths['login-client']}${search}` || '/', { replace: true });
    }

    if (client.data && client.isLoggedIn) {
        redirectClient(client.data, redirectPaths);
    }

    return (
        <MainLayout className={layout}>
            <ThemeStyle style={customStyle} />
            {client.get.isLoading && <Loader className={loader} />}
            {client.data &&
                sections.map((section, index) => {
                    return (
                        <SectionFactory
                            key={`section-${section.type}-${section.sectionId}`}
                            SectionComponent={sectionComponents[section.type]}
                            section={section}
                            TitleTag={mainSectionIndex === index ? 'h1' : 'h2'}
                        />
                    );
                })}
        </MainLayout>
    );
};

function redirectClient(client: IClient, redirectPaths: TRedirectPaths) {
    if (!isBrowser()) return;

    const pathname = window.location.pathname;
    const search = getUrlSearch();
    const params = new URLSearchParams(search);
    const hasVariantId = params.has('variantId');

    let redirectPath;

    if (client.status === EClientStatus.notVerified) {
        redirectPath = redirectPaths['verification-client'];
    }

    if (
        client.status === EClientStatus.verified &&
        hasVariantId &&
        redirectPaths['client-order-create']
    ) {
        redirectPath = `${redirectPaths['client-order-create']}${search}`;
    }

    if (!redirectPath || redirectPath === `${pathname}${search}`) return;

    navigate(redirectPath, { replace: true });
}

export const query = graphql`
    query ($pageId: Int!, $locale: String!) {
        allPage(
            filter: {
                type: {
                    in: [
                        "login-client"
                        "verification-client"
                        "client-dashboard"
                        "client-order-create"
                    ]
                }
                locale: { eq: $locale }
            }
        ) {
            edges {
                node {
                    pathname
                    type
                }
            }
        }
        page(pageId: { eq: $pageId }, locale: { eq: $locale }) {
            sections {
                ...sectionFields
            }
        }
        allThemeSetting {
            edges {
                node {
                    name
                    value
                }
            }
        }
    }
`;

export default Client;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';



const sectionComponents: Record<string, any> = {
    'header-client': SectionHeaderClient,
    'client-sms-template-preview': SectionClientSmsTemplatePreview,
    'footer': SectionFooter,
};