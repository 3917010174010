export function getDateFromUnixTimestamp(
    unixTimestamp: number,
    separator: 'dot' | 'dash' | 'slash' | 'dash-reverse' = 'dot'
): string {
    const timestamp = unixTimestamp * 1000;
    const date = new Date(timestamp);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    switch (separator) {
        case 'dot':
            return `${day}.${month}.${year}`;
        case 'dash':
            return `${day}-${month}-${year}`;
        case 'dash-reverse':
            return `${year}-${month}-${day}`;
        case 'slash':
            return `${day}/${month}/${year}`;
    }
}
