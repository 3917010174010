import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { fullHeight, alertInfo, content } from './no-permission-info.module.scss';
import { TNoPermissionModalReason } from '../modals/no-permission-modal';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import useTranslations from '../../hooks/use-translations';
import { usePagePathname } from '../../hooks/use-page-pathname';

import AlertInfo from '../molecules/alert-info';
import Markdown from '../hoc/markdown';

interface INoPermissionInfo {
    className?: string;
    reason: TNoPermissionModalReason;
    isFullHeight?: boolean;
}

interface INoPermissionInfoQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale'>>;
}

const NoPermissionInfo: React.FC<INoPermissionInfo> = ({
    className = '',
    reason,
    isFullHeight,
}) => {
    const t = useTranslations('NoPermissionModal');
    const { allPage } = useStaticQuery<INoPermissionInfoQueryResult>(query);
    const pricingPathname = usePagePathname(allPage);

    return (
        <div className={`${className} ${isFullHeight ? fullHeight : ''}`}>
            <AlertInfo className={alertInfo}>
                <Markdown className={content}>
                    {reason === 'role' ? t.role(pricingPathname) : t.plan(pricingPathname)}
                </Markdown>
            </AlertInfo>
        </div>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { eq: "pricing" } }) {
            edges {
                node {
                    pathname
                    locale
                }
            }
        }
    }
`;

export default NoPermissionInfo;
