import { useEffect, useState } from 'react';
import { usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import { useLanguageConfig } from './use-language-config';

export const useBackLink = (fallback?: string) => {
    const languageConfig = useLanguageConfig();
    const { locale } = usePageContext();

    const prefix = languageConfig.defaultLanguage === locale ? '' : locale;
    const defaultFallback = prefix ? `/${prefix}/` : '/';

    const [prevPathname, setPrevPathname] = useState(fallback || defaultFallback);

    useEffect(() => {
        const handleSessionStorage = () => {
            const newPrevPathname = getSessionStorageItem('prevPathname');
            if (!newPrevPathname) return;
            setPrevPathname(newPrevPathname);
        };
        handleSessionStorage();
        window.addEventListener('sessionStorage', handleSessionStorage);
        return () => {
            window.removeEventListener('sessionStorage', handleSessionStorage);
        };
    }, []);

    return prevPathname;
};

function getSessionStorageItem(key: string) {
    if (typeof sessionStorage === 'undefined') return null;
    return sessionStorage.getItem(key);
}
