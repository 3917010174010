// extracted by mini-css-extract-plugin
export var backgroundAnimation = "client-sms-template-preview-module--backgroundAnimation--50d4c";
export var buttons = "client-sms-template-preview-module--buttons--9eba4";
export var container = "client-sms-template-preview-module--container--489db";
export var divider = "client-sms-template-preview-module--divider--40ea5";
export var grid = "client-sms-template-preview-module--grid--3fda6";
export var isLoading = "client-sms-template-preview-module--is-loading--84c09";
export var loader = "client-sms-template-preview-module--loader--c43e7";
export var opacity = "client-sms-template-preview-module--opacity--3cffb";
export var radiusBox = "client-sms-template-preview-module--radius-box--251ad";
export var sectionBox = "client-sms-template-preview-module--section-box--66e41";
export var templateTitle = "client-sms-template-preview-module--template-title--6b77a";
export var topWrapper = "client-sms-template-preview-module--top-wrapper--477d9";
export var wrapper = "client-sms-template-preview-module--wrapper--8b0d7";