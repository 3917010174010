import alterpressApi from './base';

import { IClientSMSTemplate } from '../../models/client-sms-template.model';
import { IClientSMSTemplateValues } from '../../formik/client-sms-template.form';

export const clientSMSTemplateApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['CLIENT_SMS_TEMPLATE'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getAllClientSMSTemplates: builder.query<IClientSMSTemplate[], void>({
                query: () => `client-sms-templates/all`,
                providesTags: ['CLIENT_SMS_TEMPLATE'],
            }),
            getClientSMSTemplate: builder.query<
                IClientSMSTemplate,
                number | string | undefined | null
            >({
                query: (templateId) => `client-sms-templates/${templateId}`,
                providesTags: ['CLIENT_SMS_TEMPLATE'],
            }),
            createClientSMSTemplate: builder.mutation<IClientSMSTemplate, IClientSMSTemplateValues>(
                {
                    query: (data) => ({
                        url: `client-sms-templates`,
                        method: 'POST',
                        body: data,
                    }),
                    invalidatesTags: ['CLIENT_SMS_TEMPLATE'],
                }
            ),
            updateClientSMSTemplate: builder.mutation<
                IClientSMSTemplate,
                { templateId: number | string; data: IClientSMSTemplateValues }
            >({
                query: ({ data, templateId }) => ({
                    url: `client-sms-templates/${templateId}`,
                    method: 'PATCH',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_SMS_TEMPLATE'],
            }),
            deleteClientSMSTemplate: builder.mutation<void, number | string>({
                query: (templateId) => ({
                    url: `client-sms-templates/${templateId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['CLIENT_SMS_TEMPLATE'],
            }),
        }),
    });

export const {
    useGetAllClientSMSTemplatesQuery,
    useGetClientSMSTemplateQuery,
    useCreateClientSMSTemplateMutation,
    useUpdateClientSMSTemplateMutation,
    useDeleteClientSMSTemplateMutation,
} = clientSMSTemplateApi;
