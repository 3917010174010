import React from 'react';

import { columnTitle, columnValue } from './details-column.module.scss';

interface IDetailsColumnProps {
    label: string;
    values?: Array<string | number>;
    className?: string;
    valueColor?: string;
    valueFontWeight?: string;
    children?: React.ReactNode;
}

const DetailsColumn: React.FC<IDetailsColumnProps> = ({
    label,
    values,
    className = '',
    valueColor,
    valueFontWeight,
    children,
}) => {
    return (
        <div className={className}>
            <p className={columnTitle}>{label}</p>
            {values &&
                values.length > 0 &&
                values.map((value, i) => {
                    return (
                        <p
                            className={columnValue}
                            style={{ color: valueColor, fontWeight: valueFontWeight }}
                            key={`details-column-${i}`}
                        >
                            {value}
                        </p>
                    );
                })}
            {children}
        </div>
    );
};

export default DetailsColumn;
