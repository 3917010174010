import React from 'react';

import { container, title, titleBox, subtitleText } from './dashboard-header.module.scss';

import Tooltip from '../atoms/tooltip';

interface IDashboardHeaderProps {
    className?: string;
    TitleTag?: React.ElementType;
    children: React.ReactNode;
    subtitle?: string;
    hint?: string;
}

const DashboardHeader: React.FC<IDashboardHeaderProps> = ({
    className = '',
    TitleTag = 'h2',
    children,
    subtitle,
    hint,
}) => {
    return (
        <div className={`${container} ${className}`}>
            <div className={titleBox}>
                <TitleTag className={title}>{children}</TitleTag>
                {hint && <Tooltip>{hint}</Tooltip>}
            </div>
            {subtitle && <p className={subtitleText}>{subtitle}</p>}
        </div>
    );
};

export default DashboardHeader;
