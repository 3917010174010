import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';

import {
    sectionBox,
    grid,
    topWrapper,
    container,
    radiusBox,
    divider,
    templateTitle,
    wrapper,
    loader,
    buttons,
    isLoading,
    opacity,
} from './client-sms-template-preview.module.scss';
import { ISection } from '../../models/section.model';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { useClientSMSTemplate } from '../../hooks/use-client-sms-template';
import { getDateFromUnixTimestamp } from '../../utils/get-date-from-unix-timestamp';
import useTranslations from '../../hooks/use-translations';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { getUrlParamValue } from '../../utils/get-url-param-value';
import { useModal } from '../../hooks/use-modal';
import { useBackLink } from '../../hooks/use-back-link';

import Section from '../hoc/section';
import DashboardHeader from '../molecules/dashboard-header';
import RadiusBox from '../hoc/radius-box';
import DetailsColumn from '../atoms/details-column';
import Loader from '../atoms/loader';
import Button from '../atoms/button';
import IconFactory from '../hoc/icon-factory';
import NoPermissionInfo from '../organisms/no-permission-info';

interface IClientSMSTemplatePreview {
    className?: string;
    section: ISection;
    TitleTag?: React.ElementType;
}

interface IClientSMSTemplatePreviewQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'pathname' | 'locale' | 'type'>>;
}

const ClientSMSTemplatePreview: React.FC<IClientSMSTemplatePreview> = ({
    className = '',
    section,
    TitleTag = 'h2',
}) => {
    const { style, sectionId, css } = section;
    const t = useTranslations('ClientSMSTemplatePreview');
    const { addModal } = useModal();
    const templateId = getUrlParamValue('id');
    const template = useClientSMSTemplate({ queries: ['single'], templateId });
    const templateData = template.single.data;

    const { allPage } = useStaticQuery<IClientSMSTemplatePreviewQueryResult>(query);
    const fallbackPathname = usePagePathname(allPage, 'client-sms-templates');
    const clientSMSTemplateCreatePage = usePagePathname(allPage, 'client-sms-template-form');
    const prevPathname = useBackLink(fallbackPathname);

    const handleTemplateDelete = () => {
        if (!templateId) return;
        addModal({
            modalKey: 'delete-confirm-modal',
            modalProps: { onConfirm: handleTemplateDeleteConfirmation(templateId) },
        });
    };

    const handleTemplateDeleteConfirmation = (templateId: number | string) => {
        return async () => {
            try {
                await template.delete.fetch(templateId).unwrap();
                navigate(prevPathname);
            } catch {
                addModal({ modalKey: 'delete-error-modal' });
            }
        };
    };

    if (template.single.isUnauthorized) {
        return <NoPermissionInfo reason="role" isFullHeight={true} />;
    }

    return (
        <Section
            className={`${sectionBox} ${className} ${template.single.isLoading ? isLoading : ''} ${
                template.delete.isLoading ? opacity : ''
            }`}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {templateData ? (
                <div className={container}>
                    <div className={topWrapper}>
                        <DashboardHeader hint={t.hint} TitleTag={TitleTag}>
                            {t.title}
                        </DashboardHeader>
                        <div className={buttons}>
                            <Button
                                stylePreset="danger"
                                size="medium"
                                shape="circle"
                                onClick={handleTemplateDelete}
                            >
                                <IconFactory icon="trash" />
                            </Button>
                            <Button
                                stylePreset="secondary"
                                size="medium"
                                shape="circle"
                                as="link"
                                to={`${clientSMSTemplateCreatePage}?id=${templateData.templateId}`}
                            >
                                <IconFactory icon="edit" />
                            </Button>
                        </div>
                    </div>
                    <RadiusBox className={radiusBox}>
                        <p className={templateTitle}>
                            {t.id}: {templateData.templateId}
                        </p>
                        <div className={divider} />
                        <div className={wrapper}>
                            <DetailsColumn label={t.name} values={[`${templateData.name}`]} />
                            <DetailsColumn
                                label={t.language}
                                values={[`${templateData.language.toUpperCase()}`]}
                            />
                            <DetailsColumn
                                label={t.createdAt}
                                values={[
                                    `${getDateFromUnixTimestamp(templateData.createdAt, 'dash')}`,
                                ]}
                            />
                            <DetailsColumn label={t.author} values={[`${templateData.author}`]} />
                        </div>
                        <DetailsColumn label={t.content} values={[`${templateData.content}`]} />
                    </RadiusBox>
                </div>
            ) : (
                <Loader className={loader} />
            )}
        </Section>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { in: ["client-sms-template-form", "client-sms-templates"] } }) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
    }
`;

export default ClientSMSTemplatePreview;
